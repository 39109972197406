import request from '@/utils/request'

// 获取单点登录链接
export function getSsoUrl(url) {
  return request({
    url: '/sso/getSsoUrl',
    method: 'get',
    params: {url: url || ''}
  })
}

// 登出方法
export function logout() {
  return request({
    url: '/sso/logout',
    method: 'get'
  })
}

// 刷新token
export function renewal() {
  return request({
    url: '/sso/renewal',
    method: 'get'
  })
}

// 获取token
export function getSsoToken(query) {
  return request({
    url: '/sso/getToken',
    method: 'get',
    params: query
  })
}

// 校验token
export function validateAccessToken(query) {
  return request({
    url: '/sso/validateAccessToken',
    method: 'get',
    params: query
  })
}
