<template>
  <div>
    <el-row :gutter="24" style="margin-bottom: 24px;">
      <el-col :span="24">
        <el-button type="primary" @click="openAuthApply">申请应用权限</el-button>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="10">
        <div class="base-card-box">
          <div class="base-card-header">
            <div class="title">最近访问</div>
            <div class="tabs"></div>
            <div class="more"></div>
          </div>
          <div class="base-card-body" style="height: 300px">
            <div class="visit">
              <div class="visit-item" v-for="(item, index) in visitList"
                   @click="toSystemUrl(item)"
              >
                <div class="visit-img">
                  <img :src="item.icon?imagePreviewUrl+item.icon:null">
                </div>
                <div class="visit-name">
                  <span>{{ item.applicationName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="14">
        <div class="base-card-box">
          <div class="base-card-header">
            <div class="title">消息中心</div>
            <div class="tabs">
              <div class="tabs-item" v-for="(item, index) in msgTypeList" :key="index" :class="{active: item.msgType == messageQueryParams.msgType}" @click="handleChangeMsgType(item.msgType)">
                <el-badge :value="item.num" :hidden="item.num <= 0">
                  <span class="tabs-item--span">{{ item.name }}</span>
                </el-badge>
              </div>
            </div>
            <div class="more">
              <el-button style="padding:0;font-size: 14px" type="text" @click="toMoreMessage">更多</el-button>
            </div>
          </div>
          <div class="base-card-body" style="height: 300px">
            <el-table
              :data="messageList"
              style="width: 100%"
            >
              <el-table-column
                prop="title"
                label="标题"
                min-width="300"
                :show-overflow-tooltip="true"
              >
                <!--                <template slot-scope="scope">
                                  <el-badge is-dot class="item" v-if="scope.row.readStatus=='0'">{{ scope.row.title }}</el-badge>
                                  <span v-if="scope.row.readStatus=='1'">{{ scope.row.title }}</span>
                                </template>-->
              </el-table-column>
              <el-table-column
                prop="msgCOde"
                label="消息类型"
                width="120"
              >
                <template slot-scope="scope">
                  {{ handleMsgTypeName(scope.row.senderUser) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="msgStatus" :formatter="msgStatusFormat"
                width="120"
                label="状态"
              >
                <template slot-scope="scope">
                  <span class="status-point green" v-if="['1'].includes(scope.row.readStatus)"></span>
                  <span class="status-point red" v-if="['0'].includes(scope.row.readStatus)"></span>
                  <!--                  <span class="status-point yellow" v-if="['3'].includes(scope.row.status)"></span>-->
                  {{ scope.row.readStatus == '0' ? '未读' : '已读' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                width="150"
                label="时间"
              >
                <template slot-scope="scope">
                  {{ formatTime(scope.row.createTime) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    size="small"
                    type="text"
                    @click="handleView(scope.row,scope.index)"
                  >查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <div class="base-card-box">
          <div class="base-card-header">
            <div class="title">应用系统</div>
            <div class="tabs">
              <div class="tabs">
                <div class="tabs-item" :class="{'active': activeName === dict.applicationCategoryName}"
                     v-for="dict in applicationTypeIdOptions" :key="dict.applicationCategoryId" @click="tabClick(dict)"
                >
                  <el-badge :value="0" :hidden="true">
                    <span class="tabs-item--span">{{ dict.applicationCategoryName }}</span>
                  </el-badge>
                </div>
              </div>
            </div>
<!--            <div class="more"></div>-->
          </div>
          <div class="base-card-body">
            <el-row :gutter="24">
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" v-for="item in applicationList"
                      :key="item.applicationId"
              >
                <div class="base-sys-card-box">
                  <div class="base-sys-card-header">
                    <img class="img" :src="item.icon?imagePreviewUrl+item.icon:null" alt="">
                    <div class="title">{{ item.applicationName }}</div>
<!--                    <div class="more">more</div>-->
                  </div>
                  <div class="base-sys-card-body">
                    <div class="card-url-box">
                      <div class="url">
                        <div class="url-a">访问地址：{{ item.pcUrl }}</div>
                        <div class="url-copy">
                          <el-button icon="el-icon-link" size="small" type="text" style="padding: 0 12px 0 0"
                                     @click="handleCopyUrl(item.pcUrl)"
                          >复制链接
                          </el-button>
                        </div>
                      </div>
                      <div class="btn">
                        <el-button @click="toSystemUrl(item)" v-if="item.pcUrl" type="primary"
                                   size="small" plain
                        >立即访问
                        </el-button>
                      </div>
                    </div>

                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 权限申请 -->
    <auth-apply-add :authApplyOpen="authApplyOpen" @closeAuthApply="closeAuthApply"></auth-apply-add>

    <!-- 消息详细 -->
    <message-detail :form="messageDetailForm" :open="messageDetailOpen" @closeDetail="closeDetail"></message-detail>

  </div>
</template>

<script>
import { selectApplicationCategoryOptionList } from '@/api/application/applicationCategory'
import { selectApplicationList } from '@/api/application/application'
import { listMsg } from '@/api/msg/msgCenter'
import { checkUserAppAuth } from '@/api/application/application'
import { addApplicationVisit, userApplicationVisitList } from '@/api/application/applicationVisit'
import MessageDetail from '../system/message/messageDetail'
import AuthApplyAdd from './authApplyAdd'
import { getToken } from '@/utils/auth'
import {selectNotReadCount} from "../../api/msg/msgCenter";

export default {
  name: 'portal',
  components: { AuthApplyAdd, MessageDetail },
  watch: {
    'queryParams.applicationCategoryId': function() {
      this.getList()
    }
  },
  data() {
    return {
      activeName: 'first',
      // 应用类型ID字典
      applicationTypeIdOptions: [],
      // 应用列表
      applicationList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 9999,
        applicationCategoryId: null
      },
      //最近访问
      visitList: [],
      imagePreviewUrl: process.env.VUE_APP_IMAGE_PREVIEW,
      messageList: [],
      // 消息类型
      msgCodeList: [],
      msgStatusList: [],
      msgTypeList: [{
        name: "全部",
        msgType: '',
        num: 0
      }, {
        name: "待办",
        msgType: '1',
        num: 0
      }, {
        name: "告警",
        msgType: '2',
        num: 0
      }, {
        name: "提醒",
        msgType: '3',
        num: 0
      }, {
        name: "其它",
        msgType: '4',
        num: 0
      }],
      messageQueryParams: {
        pageNum: 1,
        pageSize: 4,
        msgType:''
      },
      // 是否显示消息详情弹出层
      messageDetailOpen: false,
      // 消息详情参数
      messageDetailForm: {},
      authApplyOpen: false,
      visitQueryParams: {
        pageNum: 1,
        pageSize: 8
      }
    }
  },
  created() {
    this.getDicts('sys_msg_code').then(response => {
      this.msgCodeList = response.data
    })
    this.getDicts('sys_msg_status').then(response => {
      this.msgStatusList = response.data
    })
    this.getMessageList()
    this.selectNotReadCount()
    this.getApplicationVisitList()
    selectApplicationCategoryOptionList({status:'1'}).then(response => {
      const applicationTypeIdOptions = this.applicationTypeIdOptions.concat(response.data)
      this.applicationTypeIdOptions = applicationTypeIdOptions
      this.activeName = applicationTypeIdOptions.length > 0 ? applicationTypeIdOptions[0].applicationCategoryName : ''
      this.queryParams.applicationCategoryId = applicationTypeIdOptions.length > 0 ? applicationTypeIdOptions[0].applicationCategoryId : ''
    })
  },
  methods: {
    handleMsgTypeName(msgType){
      if(msgType == '1'){
        return '待办消息'
      }else if(msgType == '2'){
        return '告警消息'
      }else if(msgType == '3'){
        return '提醒消息'
      }else{
        return '其他消息'
      }
    },
    formatTime(timestamps) {
      function formatNum(arg0) {
        let str = arg0.toString();
        if (str.length == 1) {
          return "0" + str;
        } else {
          return str;
        }
      }

      //毫秒补位
      function formatMilliseconds(arg) {
        var str = arg.toString();
        if (str.length == 1) {
          return "00" + str;
        } else if (str.length == 2) {
          return "0" + str;
        } else if (str.length == 3) {
          return str;
        }
      }
      let format = 'yyyy-mm-dd hh:mi:ss';
      var date = new Date(Number(timestamps));
      var finalstr=format;
      finalstr=finalstr.replace('yyyy', date.getFullYear());//年
      finalstr=finalstr.replace('mm', formatNum(date.getMonth() + 1));//月
      finalstr=finalstr.replace('dd', formatNum(date.getDate()));//天
      finalstr=finalstr.replace('hh',formatNum(date.getHours()));//时
      finalstr=finalstr.replace('mi', formatNum(date.getMinutes()));//分
      finalstr=finalstr.replace('ss', formatNum(date.getSeconds()));//秒
      //finalstr=finalstr.replace('SSS', formatMilliseconds(date.getMilliseconds()));//毫秒
      return finalstr;
    },
    // 消息类型字典翻译
    msgCodeFormat(row, column) {
      return this.selectDictLabel(this.msgCodeList, row.msgCode)
    },
    // 消息类型字典翻译
    msgStatusFormat(row, column) {
      return this.selectDictLabel(this.msgStatusList, row.status)
    },
    tabClick(tab) {
      // this.queryParams.applicationCategoryId = tab.$attrs.value
      this.queryParams.applicationCategoryId = tab.applicationCategoryId
      this.activeName = tab.applicationCategoryName
    },
    /** 查询应用列表 */
    getList() {
      selectApplicationList(this.queryParams).then(response => {
        this.applicationList = response.rows
      })
    },
    toSystemUrl(app) {
      checkUserAppAuth(app.applicationId).then(response => {
        if (response.code == '1') {
          //增加访问记录
          addApplicationVisit({ applicationId: app.applicationId }).then()
          let url = app.pcUrl;
          if(app.authType == '1'){
            url = url + (url.indexOf('?') > 0 ? '&' : '?') + 'accessToken=' + getToken()
          }
          window.open(url)
        }
      })
    },
    handleChangeMsgType(msgType){
      this.messageQueryParams.msgType = msgType;
      this.getMessageList();
    },
    /** 查询消息列表 */
    getMessageList() {
      this.messageList = [];
      this.loading = true
      listMsg(this.messageQueryParams).then(response => {
        this.messageList = response.data.data
        console.log(this.messageList)
      })
    },
    selectNotReadCount(){
      selectNotReadCount().then(response => {
        let count = 0;
        for(let i = 0; i < response.data.length; i++){
          count += response.data[i].numCount;
          for(let i1 = 0; i1 < this.msgTypeList.length; i1++){
            if(this.msgTypeList[i1].msgType == response.data[i].msgType){
              this.msgTypeList[i1].num = response.data[i].numCount
              break;
            }
          }
        }
        this.msgTypeList[0].num = count;
      })
    },
    isPC() {
      let flag = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return !flag
    },
    /** 详细按钮操作 */
    handleView(row) {
      this.messageDetailForm = row
      this.messageDetailOpen = true
    },
    closeDetail() {
      this.messageDetailOpen = false
      this.messageDetailForm.readStatus = '1'
      this.selectNotReadCount()
    },
    toMoreMessage() {
      this.$router.push('/message')
    },
    openAuthApply() {
      this.authApplyOpen = true
    },
    closeAuthApply() {
      this.authApplyOpen = false
    },
    /** 查询应用访问列表 */
    getApplicationVisitList() {
      userApplicationVisitList(this.visitQueryParams).then(response => {
        this.visitList = response.rows
        this.total = response.total
        this.loading = false
      })
    },

    handleCopyUrl(url) {
      const input = document.createElement('input')
      input.style.position = 'fixed'
      input.style.top = '-1000px'
      input.style.left = '-1000px'
      document.body.appendChild(input)
      input.setAttribute('value', url)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        this.$message.success('复制成功')
      } else {
        this.$message.error('当前浏览器不支持！')
      }
      document.body.removeChild(input)
    }

  }
}
</script>

<style scoped>
.visit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.visit .visit-item {
  width: 25%;
  text-align: center;
}

.visit .visit-item:nth-of-type(1),
.visit .visit-item:nth-of-type(2),
.visit .visit-item:nth-of-type(3),
.visit .visit-item:nth-of-type(4) {
  margin-bottom: 15px;
}

.visit .visit-item .visit-img {
  width: 50%;
  height: 50%;
  margin: 0 auto;
}

.visit .visit-item .visit-img > img {
  width: 100%;
  height: 100%;
}

.visit-name {
  height: 48px;
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
}


.status-point {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-right: 2px;
}

.status-point.green {
  background-color: #67C23A;
}

.status-point.red {
  background-color: #F26161;
}

.status-point.yellow {
  background-color: #E6A23C;
}

.base-card-box {
  background-color: #fff;
  border-radius: 4px;
}

.base-card-box .base-card-header {
  border-bottom: solid 1px #EBEEF5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  box-sizing: border-box;
}

.base-card-box .base-card-header .title {
  font-weight: 400;
  font-size: 16px;
  color: #303133;
  padding-left: 10px;
  position: relative;
}

.base-card-box .base-card-header .title:before {
  position: absolute;
  left: 0;
  top: calc(50% - 8px);
  content: '';
  display: inline-block;
  height: 16px;
  width: 3px;
  border-radius: 1.5px;
  background-color: #05C3CD;

}

.base-card-box .base-card-header .tabs {
  flex: 1;
  margin-left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.base-card-box .base-card-header .tabs-item {
  padding: 0 16px;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
}

.base-card-box .base-card-header .tabs-item.active {
  position: relative;
  color: #303133;
}

.base-card-box .base-card-header .tabs-item.active:before {
  position: absolute;
  left: 0;
  bottom: -16px;
  content: '';
  width: 100%;
  height: 2px;
  background-color: #05C3CD;
}

.base-card-box .base-card-header .tabs-item .tabs-item--span {
  padding: 0 10px;
}

.base-card-box .base-card-header .more {
  font-size: 14px;
}

.base-card-box .base-card-body {
  padding: 20px 24px;
}

</style>
