<template>
  <div class="sidebar-logo-container">
    <transition name="sidebarLogoFade">
      <router-link class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 class="sidebar-title">合肥骆岗公园 | <span>{{ title }}</span></h1>
      </router-link>
    </transition>
  </div>
</template>
<script>
import variables from '@/assets/styles/variables.scss'
export default {
  name: 'SidebarLogo',
  computed: {
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme
    }
  },
  created() {
    this.getConfigKey("sys.pc.base.config").then(response => {
      this.title = JSON.parse(response.msg).webSiteName || '工作门户';
      this.logo = this.$imagePreviewUrl + JSON.parse(response.msg).logo;
      //this.logo = 'http://bibi-znpt.oss-cn-shanghai.aliyuncs.com' + JSON.parse(response.msg).logo || logoImg;
      document.title = this.title
      const link = document.querySelector('link[rel*="icon"]') ||document.createElement('link')
      link.href = this.imagePreviewUrl + JSON.parse(response.msg).icon
    });
  },
  data() {
    return {
      title: '',
      logo: '',
      imagePreviewUrl: this.$imagePreviewUrl,
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  min-width: 240px;
  float: left;
  height: 64px;
  line-height: 64px;
  // background: #2b2f3a;
  // text-align: center;
  margin-left: 24px;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 40px;
      height: 40px;
      vertical-align: middle;
      margin-right: 16px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 64px;
      font-size: 24px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
      span{
        font-size: 18px;
      }
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
