import { login, getInfo } from '@/api/login'
import { getToken, getTokenExp, setToken, removeToken } from '@/utils/auth'
import store from "@/store";
import { validateAccessToken, getSsoToken, logout } from '@/api/sso'

const user = {
  state: {
    token: getToken(),
    tokenExp: getTokenExp(),
    name: '',
    personName: '',
    avatar: '',
    roles: [],
    permissions: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_TOKEN_EXP: (state, exp) => {
      state.tokenExp = exp
    },
    SET_PERSON_NAME: (state, personName) => {
      state.personName = personName
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 单点登录
    SSOLogin({ commit }, code){
      return new Promise((resolve, reject) => {
        getSsoToken({code: code}).then(res=>{
          setToken(res.data.accessToken, res.data.exp)
          commit('SET_TOKEN', res.data.accessToken)
          commit('SET_TOKEN_EXP', res.data.exp)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 单点登录
    ValidateAccessToken({ commit }, accessToken){
      return new Promise((resolve, reject) => {
        validateAccessToken({accessToken: accessToken}).then(res=>{
          setToken(res.data.accessToken, res.data.exp)
          commit('SET_TOKEN', res.data.accessToken)
          commit('SET_TOKEN_EXP', res.data.exp)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 设置token
    SetToken({ commit }, tokenValue){
      setToken(tokenValue)
      commit('SET_TOKEN', tokenValue)
    },
    // 登录
    Login({ commit, dispatch }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid).then(res => {
          dispatch('SetToken', res.data.tokenValue);
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          let data = res;
          const user = data.user
          const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/images/default_avatar.svg") : user.avatar;
          if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data.roles)
            commit('SET_PERMISSIONS', data.permissions)
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          commit('SET_PERSON_NAME', user.name)
          commit('SET_NAME', user.userName)
          commit('SET_AVATAR', avatar)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then((res) => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeToken()
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_PERMISSIONS', [])
        removeToken()
        resolve()
      })
    }
  }
}

export default user
