var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _vm.show
        ? _c("img", {
            staticClass: "img",
            attrs: {
              src: "https://file.hflggy.com/opfile/luogang-sso/img/2023-06-15/16868164929056000576.png",
            },
            on: {
              click: function ($event) {
                return _vm.hideImg()
              },
            },
          })
        : _vm._e(),
      _c("logo"),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "搜索",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("search", {
                      staticClass: "right-menu-item",
                      attrs: { id: "header-search" },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "全屏",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("screenfull", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "screenfull" },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "分布图",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("div", { staticClass: "right-menu-item" }, [
                      _c("i", {
                        staticClass: "el-icon-picture-outline",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.showImg()
                          },
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c(
                    "el-avatar",
                    { staticClass: "user-avatar", attrs: { src: _vm.avatar } },
                    [_c("i", { staticClass: "el-icon-user-solid" })]
                  ),
                  _c("span", { staticClass: "user-name" }, [
                    _vm._v(_vm._s(_vm.personName || "admin")),
                  ]),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/profile" } },
                    [_c("el-dropdown-item", [_vm._v("个人中心")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.setting = true
                        },
                      },
                    },
                    [_c("span", [_vm._v("布局设置")])]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [_c("span", [_vm._v("退出登录")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }