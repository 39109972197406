import request from '@/utils/request'


// 获取授权链接
export function getPreAuthUrl() {
  return request({
    url: '/wxopen/getPreAuthUrl',
    method: 'get'
  })
}



// 获取授权结果
export function getAuthorizationResult(authorizationCode) {
  return request({
    url: '/wxopen/authorizationResult?authorizationCode='+authorizationCode,
    method: 'get'
  })
}

// 代公众号授权结果
export function webAuthorizationResult(code,appid,state) {
  return request({
    url: '/wxopen/webAuthorizationResult?code='+code+'&appId='+appid+'&state='+state,
    method: 'get'
  })
}
