import request from '@/utils/request'

// 查询应用访问列表
export function listApplicationVisit(query) {
  return request({
    url: '/applicationVisit/applicationVisit/list',
    method: 'get',
    params: query
  })
}

// 查询应用访问详细
export function getApplicationVisit(applicationVisitId) {
  return request({
    url: '/applicationVisit/applicationVisit/' + applicationVisitId,
    method: 'get'
  })
}

// 新增应用访问
export function addApplicationVisit(data) {
  return request({
    url: '/applicationVisit/applicationVisit',
    method: 'post',
    data: data
  })
}

// 修改应用访问
export function updateApplicationVisit(data) {
  return request({
    url: '/applicationVisit/applicationVisit',
    method: 'put',
    data: data
  })
}

// 删除应用访问
export function delApplicationVisit(applicationVisitId) {
  return request({
    url: '/applicationVisit/applicationVisit/' + applicationVisitId,
    method: 'delete'
  })
}

// 导出应用访问
export function exportApplicationVisit(query) {
  return request({
    url: '/applicationVisit/applicationVisit/export',
    method: 'get',
    params: query
  })
}

// 查询用户应用访问列表
export function userApplicationVisitList(query) {
  return request({
    url: '/applicationVisit/applicationVisit/userApplicationVisitlist',
    method: 'get',
    params: query
  })
}
