import request from '@/utils/request'

// 查询权限申请列表
export function listApply(query) {
  return request({
    url: '/authApply/authApply/list',
    method: 'get',
    params: query
  })
}

// 查询权限申请详细
export function getApply(authApplyId) {
  return request({
    url: '/authApply/authApply/' + authApplyId,
    method: 'get'
  })
}

// 新增权限申请
export function addApply(data) {
  return request({
    url: '/authApply/authApply',
    method: 'post',
    data: data
  })
}

// 修改权限申请
export function updateApply(data) {
  return request({
    url: '/authApply/authApply',
    method: 'put',
    data: data
  })
}

// 删除权限申请
export function delApply(authApplyId) {
  return request({
    url: '/authApply/authApply/' + authApplyId,
    method: 'delete'
  })
}

// 导出权限申请
export function exportApply(query) {
  return request({
    url: '/authApply/authApply/export',
    method: 'get',
    params: query
  })
}

// 查询权限申请详情
export function getAuthApplyById(data) {
  return request({
    url: '/authApply/authApply/getAuthApplyById',
    method: 'post',
    data: data
  })
}
