var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "24px" }, attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.openAuthApply },
                },
                [_vm._v("申请应用权限")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _c("div", { staticClass: "base-card-box" }, [
              _c("div", { staticClass: "base-card-header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("最近访问")]),
                _c("div", { staticClass: "tabs" }),
                _c("div", { staticClass: "more" }),
              ]),
              _c(
                "div",
                {
                  staticClass: "base-card-body",
                  staticStyle: { height: "300px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "visit" },
                    _vm._l(_vm.visitList, function (item, index) {
                      return _c(
                        "div",
                        {
                          staticClass: "visit-item",
                          on: {
                            click: function ($event) {
                              return _vm.toSystemUrl(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "visit-img" }, [
                            _c("img", {
                              attrs: {
                                src: item.icon
                                  ? _vm.imagePreviewUrl + item.icon
                                  : null,
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "visit-name" }, [
                            _c("span", [_vm._v(_vm._s(item.applicationName))]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
          _c("el-col", { attrs: { span: 14 } }, [
            _c("div", { staticClass: "base-card-box" }, [
              _c("div", { staticClass: "base-card-header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("消息中心")]),
                _c(
                  "div",
                  { staticClass: "tabs" },
                  _vm._l(_vm.msgTypeList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "tabs-item",
                        class: {
                          active:
                            item.msgType == _vm.messageQueryParams.msgType,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleChangeMsgType(item.msgType)
                          },
                        },
                      },
                      [
                        _c(
                          "el-badge",
                          { attrs: { value: item.num, hidden: item.num <= 0 } },
                          [
                            _c("span", { staticClass: "tabs-item--span" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "more" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "0", "font-size": "14px" },
                        attrs: { type: "text" },
                        on: { click: _vm.toMoreMessage },
                      },
                      [_vm._v("更多")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "base-card-body",
                  staticStyle: { height: "300px" },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.messageList },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: "标题",
                          "min-width": "300",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "msgCOde",
                          label: "消息类型",
                          width: "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.handleMsgTypeName(
                                        scope.row.senderUser
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "msgStatus",
                          formatter: _vm.msgStatusFormat,
                          width: "120",
                          label: "状态",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                ["1"].includes(scope.row.readStatus)
                                  ? _c("span", {
                                      staticClass: "status-point green",
                                    })
                                  : _vm._e(),
                                ["0"].includes(scope.row.readStatus)
                                  ? _c("span", {
                                      staticClass: "status-point red",
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.readStatus == "0"
                                        ? "未读"
                                        : "已读"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          width: "150",
                          label: "时间",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatTime(scope.row.createTime)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleView(
                                          scope.row,
                                          scope.index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "base-card-box" }, [
              _c("div", { staticClass: "base-card-header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("应用系统")]),
                _c("div", { staticClass: "tabs" }, [
                  _c(
                    "div",
                    { staticClass: "tabs" },
                    _vm._l(_vm.applicationTypeIdOptions, function (dict) {
                      return _c(
                        "div",
                        {
                          key: dict.applicationCategoryId,
                          staticClass: "tabs-item",
                          class: {
                            active:
                              _vm.activeName === dict.applicationCategoryName,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.tabClick(dict)
                            },
                          },
                        },
                        [
                          _c(
                            "el-badge",
                            { attrs: { value: 0, hidden: true } },
                            [
                              _c("span", { staticClass: "tabs-item--span" }, [
                                _vm._v(_vm._s(dict.applicationCategoryName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "base-card-body" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    _vm._l(_vm.applicationList, function (item) {
                      return _c(
                        "el-col",
                        {
                          key: item.applicationId,
                          attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                        },
                        [
                          _c("div", { staticClass: "base-sys-card-box" }, [
                            _c("div", { staticClass: "base-sys-card-header" }, [
                              _c("img", {
                                staticClass: "img",
                                attrs: {
                                  src: item.icon
                                    ? _vm.imagePreviewUrl + item.icon
                                    : null,
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "title" }, [
                                _vm._v(_vm._s(item.applicationName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "base-sys-card-body" }, [
                              _c("div", { staticClass: "card-url-box" }, [
                                _c("div", { staticClass: "url" }, [
                                  _c("div", { staticClass: "url-a" }, [
                                    _vm._v("访问地址：" + _vm._s(item.pcUrl)),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "url-copy" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            padding: "0 12px 0 0",
                                          },
                                          attrs: {
                                            icon: "el-icon-link",
                                            size: "small",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCopyUrl(
                                                item.pcUrl
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("复制链接 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "btn" },
                                  [
                                    item.pcUrl
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "small",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toSystemUrl(item)
                                              },
                                            },
                                          },
                                          [_vm._v("立即访问 ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("auth-apply-add", {
        attrs: { authApplyOpen: _vm.authApplyOpen },
        on: { closeAuthApply: _vm.closeAuthApply },
      }),
      _c("message-detail", {
        attrs: { form: _vm.messageDetailForm, open: _vm.messageDetailOpen },
        on: { closeDetail: _vm.closeDetail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }