<template>
  <el-dialog :close-on-click-modal="false" title="权限申请" :visible.sync="authApplyOpen" :before-close="closeDialog" width="600px" append-to-body>
    <el-form ref="applyForm" :model="form" :rules="rules" label-width="110px">
      <el-form-item label="申请访问系统" prop="applicationId">
        <el-select v-model="form.applicationId" placeholder="请选择应用系统">
          <el-option
            v-for="item in applicationList"
            :key="item.applicationId"
            :label="item.applicationName"
            :value="item.applicationId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开放时间" prop="dateRange">
          <el-date-picker
            v-model="form.dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
      </el-form-item>
      <el-form-item label="申请理由" prop="applyReason">
        <el-input v-model="form.applyReason" type="textarea" placeholder="请输入申请理由" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { listApplication } from "@/api/application/application";
import { addApply } from "@/api/authApply/authApply";
import {optionsApplication} from "../../api/application/application";

export default {
  name: "authApplyAdd",
  props: ['authApplyOpen'],
  created() {
    this.getApplicationList();
  },
  data(){
    return{
      form:{
        applicationId:null,
        applyReason:null,
        dateRange:[]
      },
      // 表单校验
      rules: {
        applicationId: [
          { required: true, message: "请选择申请系统", trigger: "blur" }
        ],
        applyReason: [
          { required: true, message: "请填写申请理由", trigger: "blur" }
        ],
        dateRange: [
          { required: true, message: "请填写申请时间", trigger: "blur" }
        ],
      },
      //应用列表
      applicationList:[]
    }
  },
  methods:{
    closeDialog(){
      this.$emit('closeAuthApply');
    },
    /** 查询应用列表 */
    getApplicationList() {
      optionsApplication(this.queryParams).then(response => {
        this.applicationList = response.rows;
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["applyForm"].validate(valid => {
        if (valid) {
          console.log(this.form.dateRange)
          this.form.openStartTime = this.form.dateRange[0] + " 00:00:00";
          this.form.openEndTime = this.form.dateRange[1] + " 23:59:59";
          addApply(this.form).then(response => {
            this.msgSuccess("提交申请成功");
            this.$emit('closeAuthApply');
            this.form = {
              applicationId:null,
              applyReason:null,
              dateRange:[]
            }
          });
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
