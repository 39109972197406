import request from '@/utils/request'

// 查询应用分类列表
export function listApplicationCategory(query) {
  return request({
    url: '/system/applicationCategory/list',
    method: 'get',
    params: query
  })
}

// 查询应用分类详细
export function getApplicationCategory(applicationCategoryId) {
  return request({
    url: '/system/applicationCategory/' + applicationCategoryId,
    method: 'get'
  })
}

// 新增应用分类
export function addApplicationCategory(data) {
  return request({
    url: '/system/applicationCategory',
    method: 'post',
    data: data
  })
}

// 修改应用分类
export function updateApplicationCategory(data) {
  return request({
    url: '/system/applicationCategory',
    method: 'put',
    data: data
  })
}

// 删除应用分类
export function delApplicationCategory(applicationCategoryId) {
  return request({
    url: '/system/applicationCategory/' + applicationCategoryId,
    method: 'delete'
  })
}

// 导出应用分类
export function exportApplicationCategory(query) {
  return request({
    url: '/system/applicationCategory/export',
    method: 'get',
    params: query
  })
}

// 查询应用分类选项
export function selectApplicationCategoryOptionList(query) {
  return request({
    url: '/system/applicationCategory/selectApplicationCategoryOptionList',
    method: 'get',
    params: query || {}
  })
}
