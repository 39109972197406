import request from '@/utils/request'

// 查询应用列表
export function optionsApplication(query) {
  return request({
    url: '/system/application/options',
    method: 'get',
    params: query
  })
}

// 查询应用列表
export function selectApplicationList(query) {
  return request({
    url: '/system/application/selectApplicationList',
    method: 'get',
    params: query
  })
}
// 查询应用列表
export function listApplication(query) {
  return request({
    url: '/system/application/list',
    method: 'get',
    params: query
  })
}

// 查询应用详细
export function getApplication(applicationId) {
  return request({
    url: '/system/application/' + applicationId,
    method: 'get'
  })
}

// 新增应用
export function addApplication(data) {
  return request({
    url: '/system/application',
    method: 'post',
    data: data
  })
}

// 修改应用
export function updateApplication(data) {
  return request({
    url: '/system/application',
    method: 'put',
    data: data
  })
}

// 删除应用
export function delApplication(applicationId) {
  return request({
    url: '/system/application/' + applicationId,
    method: 'delete'
  })
}

// 导出应用
export function exportApplication(query) {
  return request({
    url: '/system/application/export',
    method: 'get',
    params: query
  })
}

// 新增授权
export function addAuthorization(data) {
  return request({
    url: '/system/application/authorization',
    method: 'post',
    data: data
  })
}

// 查询应用授权列表
export function listApplicationAuthorization(query) {
  return request({
    url: '/system/application/authorizationList',
    method: 'get',
    params: query
  })
}

// 新增授权
export function addUserAuthorization(data) {
  return request({
    url: '/system/application/userAuthorization',
    method: 'post',
    data: data
  })
}

// 更新授权
export function updateUserAuthorization(data) {
  return request({
    url: '/system/application/updateUserAuthorization',
    method: 'post',
    data: data
  })
}

// 检查用户应用权限
export function checkUserAppAuth(applicationId) {
  return request({
    url: '/system/application/checkUserAppAuth?applicationId='+applicationId,
    method: 'get',
  })
}

// 查询角色应用授权列表
export function listRoleApplicationAuthorization(query) {
  return request({
    url: '/system/application/roleAuthorizationList',
    method: 'get',
    params: query
  })
}

// 新增角色应用授权
export function addRoleAuthorization(data) {
  return request({
    url: '/system/application/roleAuthorization',
    method: 'post',
    data: data
  })
}

// 用户删除授权
export function deleteUserAuthorization(data) {
  return request({
    url: '/system/application/deleteUserAuthorization',
    method: 'post',
    data: data
  })
}

// 角色删除授权
export function removeRoleAuthorization(data) {
  return request({
    url: '/system/application/removeRoleAuthorization',
    method: 'post',
    data: data
  })
}

export function removeRoleAuthorizationList(data) {
  return request({
    url: '/system/application/removeRoleAuthorizationList',
    method: 'post',
    data: data
  })
}

