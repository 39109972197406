var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      class: { "detail-disabled": _vm.disabled },
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "80px",
        disabled: _vm.disabled,
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "申请人", prop: "remark" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.remark,
              callback: function ($$v) {
                _vm.$set(_vm.form, "remark", $$v)
              },
              expression: "form.remark",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "申请访问系统", prop: "applicationId" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择应用系统" },
              model: {
                value: _vm.form.applicationId,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "applicationId", $$v)
                },
                expression: "form.applicationId",
              },
            },
            _vm._l(_vm.applicationList, function (item) {
              return _c("el-option", {
                key: item.applicationId,
                attrs: {
                  label: item.applicationName,
                  value: item.applicationId,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "开放时间", prop: "dateRange" } },
        [
          _c("el-date-picker", {
            attrs: {
              type: "datetimerange",
              "value-format": "yyyy-MM-dd HH:mm:ss",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
            },
            model: {
              value: _vm.form.dateRange,
              callback: function ($$v) {
                _vm.$set(_vm.form, "dateRange", $$v)
              },
              expression: "form.dateRange",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "申请理由", prop: "applyReason" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", placeholder: "请输入申请理由" },
            model: {
              value: _vm.form.applyReason,
              callback: function ($$v) {
                _vm.$set(_vm.form, "applyReason", $$v)
              },
              expression: "form.applyReason",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }