import request from '@/utils/request'

// 查询统一消息列表
export function listMsg(query) {
  return request({
    url: '/biz/msgCenter/list',
    method: 'get',
    params: query
  })
}

// 查询统一消息详细
export function selectNotReadCount() {
  return request({
    url: '/biz/msgCenter/selectNotReadCount',
    method: 'get'
  })
}

// 修改统一消息
export function setRead(mid) {
  return request({
    url: '/biz/msgCenter/setRead',
    method: 'get',
    params: {mid:mid}
  })
}
