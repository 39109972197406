<template>
  <el-dialog :close-on-click-modal="false" title="消息详细" :visible.sync="open" @close="closeDetail" width="700px" append-to-body>
    <el-form ref="form" :model="form" label-width="100px" size="mini">
      <el-row>
        <el-col :span="24">
          <div class="message-title">{{ detailForm.title }}</div>
        </el-col>
        <template v-if="contentField && contentField.length>0">
          <el-col :span="24"  v-for="(item, index) in contentField" :key="index">
            <el-form-item :label="item.label">{{ item.value }}</el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary"  v-if="detailForm.redirectUrl || detailForm.redirectPcUrl" @click="toDetailPage()">去处理</el-button>
      <el-button @click="closeDetail">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { setRead } from "@/api/msg/msgCenter";
import { getMessageFieldTemplate } from '@/api/system/messageFieldTemplate'
import { getToken } from '@/utils/auth'
export default {
  name: "messageDetail",
  props: ['form','open'],
  watch:{
    open:function (){
      if(open){
        let data = this.form;
        this.detailForm = data;
        if(this.detailForm.readStatus=="0"){
          //设置为已读
          this.handleSetRead();
        }
        if(data.content){
          let content = JSON.parse(data.content);
          data.redirectUrl = content["redirectUrl"];
          data.redirectPcUrl = content["redirectPcUrl"];
          getMessageFieldTemplate(content.sendSystemType).then(res=>{
            this.fieldTemplate = JSON.parse(res.data.fieldTemplate || '[]')
            let contentArr = [];
            for(let i = 0; i < this.fieldTemplate.length; i++){
              let field = this.fieldTemplate[i];
              let value = '';

              if(field.isFormatJson){
                content[field.field] = JSON.parse(content[field.field]);
                continue
              }

              if(field.field.indexOf('.')<0){
                value = content[field.field];
              }else{
                let fieldArr = field.field.split(".");
                value = content[fieldArr[0]];
                for(let i = 1; i < fieldArr.length; i++){
                  value = value[fieldArr[i]];
                }
              }
              if(field.dataType){
                if(field.dataType == 'timestamp'){
                  value = this.formatTime(value)
                }
              }
              if(field.format && field.format.length > 0){
                for(let i = 0; i < field.format.length; i++){
                  if(value == field.format[i].code){
                    value = field.format[i].value;
                    break;
                  }
                }
              }

              if(value){
                contentArr.push({
                  label: field.label + ":",
                  value: value
                })
              }
            }
            this.contentField = contentArr;
          })
        }
      }
    }
  },
  created() {
    this.getDicts("sys_msg_code").then(response => {
      this.msgCodeList = response.data;
    });
    this.getDicts("sys_msg_status").then(response => {
      this.msgStatusList = response.data;
    });
  },
  data() {
    return {
      activeName: "first",
      detailForm :{},
      applyList:[],
      msgCodeList:[],
      msgStatusList:[],
      fieldTemplate:[],
      contentField:[]
    };
  },
  methods:{
    toDetailPage() {
      if (this.detailForm.redirectPcUrl) {
        let url = this.detailForm.redirectPcUrl;
        if(url.indexOf("http://") == 0 || url.indexOf("https://") == 0){
          window.open(url + (url.indexOf('?') > 0 ? '&' : '?') + 'accessToken=' + getToken())
        }else{
          this.$router.push({
            path: this.detailForm.redirectPcUrl
          })
        }
      } else if (this.detailForm.redirectUrl) {
        this.msgInfo('本消息不支持PC查看，请到工作人员APP查看并处理')
      }
    },
    formatTime(timestamps) {
      if(!timestamps){
        return
      }
      function formatNum(arg0) {
        let str = arg0.toString();
        if (str.length == 1) {
          return "0" + str;
        } else {
          return str;
        }
      }

      //毫秒补位
      function formatMilliseconds(arg) {
        var str = arg.toString();
        if (str.length == 1) {
          return "00" + str;
        } else if (str.length == 2) {
          return "0" + str;
        } else if (str.length == 3) {
          return str;
        }
      }
      let format = 'yyyy-mm-dd hh:mi:ss';
      var date = new Date(typeof timestamps == 'string' ? Number(timestamps) : timestamps);
      var finalstr=format;
      finalstr=finalstr.replace('yyyy', date.getFullYear());//年
      finalstr=finalstr.replace('mm', formatNum(date.getMonth() + 1));//月
      finalstr=finalstr.replace('dd', formatNum(date.getDate()));//天
      finalstr=finalstr.replace('hh',formatNum(date.getHours()));//时
      finalstr=finalstr.replace('mi', formatNum(date.getMinutes()));//分
      finalstr=finalstr.replace('ss', formatNum(date.getSeconds()));//秒
      //finalstr=finalstr.replace('SSS', formatMilliseconds(date.getMilliseconds()));//毫秒
      return finalstr;
    },
    // 消息类型字典翻译
    msgCodeFormat(row, column) {
      return this.selectDictLabel(this.msgCodeList, this.detailForm.msgCode);
    },
    // 消息类型字典翻译
    msgStatusFormat(row, column) {
      return this.selectDictLabel(this.msgStatusList, this.detailForm.status);
    },
    closeDetail(){
      this.$emit('closeDetail');
    },
    handleSetRead(){
      this.detailForm.readStatus = '1'
      setRead(this.detailForm.mid).then(()=>{
        this.$emit('selectNotReadCount');
      });
    }
  }
}
</script>

<style scoped>
.message-title{
  font-size: 18px;
  color: #303133;
  font-weight: bold;
  margin-bottom: 18px;
}
</style>
