<template>
<div>
  <auth-apply-from v-if="businessType == 'authApply'" :id="businessId"></auth-apply-from>
</div>
</template>

<script>
import AuthApplyFrom from './form/authApplyFrom'
export default {
  name: 'businessForm',
  components: { AuthApplyFrom },
  props: {
    businessId: {
      required: true
    },
    businessType: {
      required: true
    }
  }
}
</script>
