var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "消息详细",
        visible: _vm.open,
        width: "700px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.open = $event
        },
        close: _vm.closeDetail,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "100px", size: "mini" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "message-title" }, [
                  _vm._v(_vm._s(_vm.detailForm.title)),
                ]),
              ]),
              _vm.contentField && _vm.contentField.length > 0
                ? _vm._l(_vm.contentField, function (item, index) {
                    return _c(
                      "el-col",
                      { key: index, attrs: { span: 24 } },
                      [
                        _c("el-form-item", { attrs: { label: item.label } }, [
                          _vm._v(_vm._s(item.value)),
                        ]),
                      ],
                      1
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.detailForm.redirectUrl || _vm.detailForm.redirectPcUrl
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.toDetailPage()
                    },
                  },
                },
                [_vm._v("去处理")]
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.closeDetail } }, [
            _vm._v("关 闭"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }