<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="80px" :disabled="disabled"
           :class="{'detail-disabled': disabled}">
    <el-form-item label="申请人" prop="remark">
      <el-input v-model="form.remark"/>
    </el-form-item>
    <el-form-item label="申请访问系统" prop="applicationId">
      <el-select v-model="form.applicationId" placeholder="请选择应用系统">
        <el-option
          v-for="item in applicationList"
          :key="item.applicationId"
          :label="item.applicationName"
          :value="item.applicationId"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="开放时间" prop="dateRange">
      <el-date-picker
        v-model="form.dateRange"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </el-form-item>
    <el-form-item label="申请理由" prop="applyReason">
      <el-input v-model="form.applyReason" type="textarea" placeholder="请输入申请理由" />
    </el-form-item>
  </el-form>
</template>

<script>
import {getAuthApplyById} from "@/api/authApply/authApply";
import { optionsApplication } from '@/api/application/application'
import { listApplication } from "@/api/application/application";

export default {
  name: 'authApplyFrom',
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      // 表单参数
      form: {},
      applicationList: [],
      disabled: true,
    };
  },
  created() {
    this.getFormData();
    this.getApplicationList();
  },
  methods: {
    /** 查询应用列表 */
    getApplicationList() {
      optionsApplication(this.queryParams).then(response => {
        this.applicationList = response.rows;
      });
    },
    getFormData() {
      getAuthApplyById({authApplyId: this.id}).then(response => {
        this.form = response.data;
        this.form.dateRange = [response.data.openStartTime, response.data.openEndTime]
        this.disabled = true;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.el-date-editor, .el-select {
  width: 100%;
}
</style>
