import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, setToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'
import {getSsoUrl} from "@/api/sso";

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/bind', '/register']

router.beforeEach((to, from, next) => {
  NProgress.start()

  const loginNext = ()=>{
    if (store.getters.roles.length === 0) {
      isRelogin.show = true
      // 判断当前用户是否已拉取完user_info信息
      store.dispatch('GetInfo').then(() => {
        isRelogin.show = false
        store.dispatch('GenerateRoutes').then(accessRoutes => {
          // 根据roles权限生成可访问的路由表
          router.addRoutes(accessRoutes) // 动态添加可访问路由表
          next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
        })
      }).catch(err => {
        store.dispatch('LogOut').then(ssoUrl => {
          Message.error(err)
          location.href = ssoUrl + encodeURIComponent(getUrl())
        })
      })
    } else {
      next()
    }
  }

  const toLogin = () => {
    getSsoUrl().then(res=>{
      NProgress.done()
      location.href = res.data + encodeURIComponent(getUrl())
    })
  }

  const getUrl = () =>{
    return window.location.href.replace(/(\?|&)(authorizationCode|accessToken|access_token)=[^&]*(&)?/g, (p0, p1, p2, p3) => {return p1 === '?' ? p3 ? '?' : '' : p3 === '&' ? '&' : ''})
  }

  if(to.query.accessToken || to.query.access_token){
    //统一登录鉴权回调
    store.dispatch("ValidateAccessToken", to.query.accessToken || to.query.access_token).then(() => {
      location.href = getUrl()
    }).catch(() => {
      toLogin()
    });
  } else if(to.query.authorizationCode){
    //统一登录鉴权回调
    store.dispatch("SSOLogin", to.query.authorizationCode).then(() => {
      location.href = getUrl()
    }).catch(() => {
      toLogin()
    });
  } else if (getToken()) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      loginNext()
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // 否则全部重定向到登录页
      toLogin()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
